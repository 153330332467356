import { useLocalStorage } from '#imports'
import type { Message } from 'google-protobuf'
import type { Request, UnaryInterceptor, UnaryResponse } from 'grpc-web'

type InterceptorRequest = Request<Message, Message>
type InterceptorUnaryResponse = UnaryResponse<Message, Message>

export class TokenAuthInterceptor implements UnaryInterceptor<Message, Message> {
  intercept (
    request: InterceptorRequest,
    invoker: (invokerRequest: InterceptorRequest) => Promise<InterceptorUnaryResponse>
  ): Promise<InterceptorUnaryResponse> {
    const token = useLocalStorage('csrf-token')

    if (token.value) {
      request.getMetadata()['X-csrf-token'] = token.value
    }

    return invoker(request)
  }
}
