import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_PQZICtvjHK from "/app/modules/nuxt-cache/plugin.ts";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8I4LkgGwQ1 from "/app/modules/nuxt-localization/plugin.ts";
import plugin_UhZsn9DZ4v from "/app/modules/nuxt-yandex-maps/plugin.ts";
import plugin_luXBKqpx4f from "/app/modules/nuxt-notify/plugin.ts";
import plugin_smGKsp4shu from "/app/modules/nuxt-devlogs/plugin.ts";
import plugin_client_PJfdZhJJlR from "/app/modules/nuxt-admin/plugin-client.ts";
import custom_show_lYQXFwplQl from "/app/plugins/custom-show.ts";
import modals_RaVbrZQ2Fe from "/app/plugins/modals.ts";
import vue_touch_events_P3TJLnzVGD from "/app/plugins/vue-touch-events.ts";
import app_config_zgG4dBcgoH from "/app/plugins/app-config.ts";
import check_region_VeFw1wtE7z from "/app/modules/nuxt-redirects/check-region.ts";
import plugin_jFIUWqc7wS from "/app/modules/nuxt-auth/plugin.ts";
import plugin_EOirqVKsdc from "/app/modules/nuxt-metrica/plugin.ts";
import plugin_4N9vg42XLU from "/app/modules/nuxt-api/plugin.ts";
import index_l2HcN5EU7a from "/app/modules/nuxt-metrica/flocktory/index.ts";
import index_xstx2Ujvm0 from "/app/modules/nuxt-metrica/clicky/index.ts";
import index_g6tZQ2fhHv from "/app/modules/nuxt-metrica/yandex/index.ts";
import index_FwyvMtgB1A from "/app/modules/nuxt-metrica/google/index.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  plugin_PQZICtvjHK,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8I4LkgGwQ1,
  plugin_UhZsn9DZ4v,
  plugin_luXBKqpx4f,
  plugin_smGKsp4shu,
  plugin_client_PJfdZhJJlR,
  custom_show_lYQXFwplQl,
  modals_RaVbrZQ2Fe,
  vue_touch_events_P3TJLnzVGD,
  app_config_zgG4dBcgoH,
  check_region_VeFw1wtE7z,
  plugin_jFIUWqc7wS,
  plugin_EOirqVKsdc,
  plugin_4N9vg42XLU,
  index_l2HcN5EU7a,
  index_xstx2Ujvm0,
  index_g6tZQ2fhHv,
  index_FwyvMtgB1A
]