import { defineNuxtPlugin } from '#app'
import { useLocalStorage, useHydrationRef, useContextData, useSentry } from '#imports'
import { TokenAuthInterceptor } from './interceptors/token-interceptor'

export default defineNuxtPlugin({
  name: 'token-request',
  order: 4,
  async setup () {
    const sentry = useSentry()
    const apiInterceptors = useContextData<any>('api-interceptors', [])

    const csrfToken = useHydrationRef('csrf-token', '')
    const sessionToken = useHydrationRef('session-token', '')
    const token = useLocalStorage('csrf-token')

    sentry.setTag('shop-session-token', sessionToken.value)
    sentry.addBreadcrumb({
      category: 'auth',
      message: 'Create tokens',
      level: 'info'
    })

    token.value = csrfToken.value

    apiInterceptors.value.push(new TokenAuthInterceptor())
  }
})
