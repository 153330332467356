import { useMainDomainCookie } from '@/composables/cookie'
import { useRequestURL, useNuxtApp, useRuntimeConfig } from '#app'
import { useConfig } from '@/stores/config'
import { useHydrationRef } from '@/modules/nuxt-utils/runtime/hydration-ref'

import type { RouteLocationRaw } from 'vue-router'
import type { LocationCity } from '@/types/locale'
import type { Region } from '@/modules/nuxt-api/models/Region'

export function useRedirect () {
  function createForm (url: string) {
    const form = document.createElement('form')

    form.method = 'POST'
    form.action = url

    return {
      setValue (name: string, value: string) {
        const input = document.createElement('input')

        input.name = name
        input.value = value
        form.appendChild(input)

        return this
      },
      submit () {
        document.body.appendChild(form)

        form.submit()
      }
    }
  }

  function createPostRedirect (to: string, params?: Record<string, number | string | boolean>) {
    if (import.meta.client) {
      const form = createForm(to)

      if (params?.need_redirect === undefined) {
        form.setValue('need_redirect', '1')
      }

      if (params) {
        Object.entries(params).forEach(([k, v]) => {
          form.setValue(k, v.toString())
        })
      }

      form.submit()
    }
  }

  function getRedirectUrl (region: Region | LocationCity, withDomain: boolean = false) {
    const { currentRegion, testRegionSubdomain } = useConfig()

    const protocol = useRuntimeConfig().public.nodeEnv === 'development' ? 'http' : 'https'
    let splitHost = useRequestURL().host
      .split(':')[0]
      .split('.')
      .filter(el => el !== currentRegion.value?.subdomain && el !== testRegionSubdomain.value && el !== 'eng')

    if (withDomain && region.domain) {
      splitHost = splitHost.filter(el => el !== currentRegion.value?.domain)
      splitHost.push(region.domain)
    }

    if (region.subdomain) {
      splitHost.unshift(region.subdomain)
    }

    const url = splitHost.join('.')
    const path = useRequestURL().pathname

    return `${protocol}://${url}${path}`
  }

  function addToRedirectCookie (to: number | string, from?: number | string) {
    const expiresDate = new Date()
    expiresDate.setFullYear(expiresDate.getFullYear() + 3)

    const redirectedToFrom = useMainDomainCookie('redirected-to-from', { expires: expiresDate })
    redirectedToFrom.value = Array.from(new Set([to, from].filter(Boolean).map(el => el?.toString()))).join('-')
  }

  function checkIfExistInRedirectCookie (newId: number | string) {
    // temporary hack for compatibility with legacy
    const cityChoosed = useMainDomainCookie('CITYCHOOSED')
    const regionsInCookie = (cityChoosed.value || '').toString().split(',')
    if (regionsInCookie.includes('1') && regionsInCookie.includes(newId.toString())) {
      addToRedirectCookie(newId, 1)
      return true
    }
    // end of hack

    return (useMainDomainCookie('redirected-to-from').value || '')
      .toString()
      .split('-')
      .findIndex((el: string) => el === newId.toString()) === 0
  }

  function checkCookieAndRedirect () {
    const { currentRegion, regions } = useConfig()
    const redirectedToFrom = (useMainDomainCookie('redirected-to-from').value ?? '').toString().split('-')
    const to = Number(redirectedToFrom[0])
    const from = Number(redirectedToFrom[1])

    if (to && from === currentRegion.value.id) {
      const region = regions.value
        .find((region: Region) => region.id === to)

      if (region) {
        changeCountry(region)

        return true
      }
    }

    return false
  }

  function changeCountry (region: Region & { allRegions?: boolean }) {
    const { currentRegion } = useConfig()
    const isRuRegion = region.country?.code === 'ru'
    const url = getRedirectUrl(region, true)

    createPostRedirect(url, {
      from_country: currentRegion.value.id,
      no_confirm: 1,
      need_redirect: isRuRegion && !region.allRegions ? 0 : 1,
      all_regions: isRuRegion && region.allRegions ? 1 : 0
    })
  }

  function changeCity (city: LocationCity) {
    const { currentRegion } = useConfig()
    const redirectData = useHydrationRef('redirect-data')

    const expiresDate = new Date()
    expiresDate.setFullYear(expiresDate.getFullYear() + 3)
    const allRegions = useMainDomainCookie('all-regions', { expires: expiresDate })
    const countrySelectedYetNew = useMainDomainCookie('countrySelectedYetnew')

    if (city?.id) {
      addToRedirectCookie(city.id, redirectData.value?.from_country ?? currentRegion.value.id)

      allRegions.value = city?.allRegions ? 1 : 0
    }

    countrySelectedYetNew.value = '1'

    const url = getRedirectUrl(city)
    createPostRedirect(url, {
      from_country: redirectData.value?.from_country ?? currentRegion.value.id,
      no_confirm: 1
    })
  }

  function redirectIfFromReferrer (to: RouteLocationRaw) {
    const host = useRequestURL().host ?? ''
    const { currentRegion } = useConfig()

    const referrer = process.server
      ? useNuxtApp().ssrContext?.event.node.req.headers.referer
      : document.referrer

    let referrerURL = ''
    try {
      if (referrer) {
        const url = new URL(referrer)
        referrerURL = `${url.protocol}//${url.hostname}`
      }
    } catch (error) {
      console.error('Invalid referrer URL:', error)
    }

    const hostWithoutSubdomains = host.split(':')[0]
      .replace(currentRegion.value.subdomain || '', '')
      .replace(/^\./g, '')

    if (referrerURL.includes(hostWithoutSubdomains)) {
      return navigateTo(to)
    }

    return false
  }

  return {
    createPostRedirect,
    addToRedirectCookie,
    checkIfExistInRedirectCookie,
    checkCookieAndRedirect,
    changeCountry,
    changeCity,
    redirectIfFromReferrer
  }
}
